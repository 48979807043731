import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { API_HOST } from '../settings/constants'

import ModalHeader from '../common/ModalHeader'

class CancelSubscriptionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedReason: null,
      comment: '',
      authToken: null,
      submitButtonText: 'Cancel Subscription'
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleReasonChange = this.handleReasonChange.bind(this)
    this.handleCommentChange = this.handleCommentChange.bind(this)
  }

  componentDidMount() {
    const token = document.querySelector('meta[name="auth-token"]').getAttribute('content')
    
    if(token) {
      this.setState({ authToken: `Bearer ${token}`})
    }
  }

  handleReasonChange = e => {
    this.setState({
      selectedReason: e.target.value
    })
  }

  handleCommentChange = e => {
    this.setState({
      comment: e.target.value
    })
  }

  validForm = () => {
    return !!(this.state.selectedReason)
  }

  handleSubmit() {
    const { authToken, selectedReason, comment } = this.state
    const URL = `${API_HOST}/subscriptions/${this.props.subscriptionId}`

    if(!this.validForm() || !authToken) {
      return
    }

    this.setState({
      submitButtonText: 'Canceling Subscription...' 
    })

    const self = this
    axios.delete(URL, {
      headers: {
        Authorization: authToken
      },
      data: {
        reason: selectedReason,
        comment: comment
      }
    })
    .then(() => {
      Turbolinks.clearCache()
      let url = window.location.href
      url = url + "?cancel_success=true"
      Turbolinks.visit(url)
    })
    .catch(error => {
      self.setState({
        submitButtonText: 'Cancel Subscription',
        error: 'There was an error canceling your subscription. Our team has been alerted and will fix this shortly!'
      })
    })
  }

  renderCancelReasons() {
    const items = this.props.reasons.map((reason, index) =>
      <div className="form-check mb-1" key={index}> 
        <input 
          className="form-check-input" 
          type="radio" 
          id={`${reason.key}${index}`} 
          name={`${reason.key}${index}`} 
          value={reason.key} 
          checked={this.state.selectedReason === reason.key}   
          onChange={this.handleReasonChange}           
        />
        <label className="form-check-label" htmlFor={`${reason.key}${index}`}>{reason.value}</label>
      </div>
    )

    return (
      <div>{items}</div>
    )
  }

  render() {
    const { authToken, comment, error, submitButtonText } = this.state
    const isDisabled =!(authToken && this.validForm())

    return (
      <div className='modal-dialog modal-dialog-centered'>
        <div className="modal-content">
          <ModalHeader title='Cancel subscription' />
          <div className="modal-body">
            {
              error && 
              <div className='alert alert-danger' role='alert'>
                <i className='fas fa-exclamation-triangle'></i>&nbsp;
                {error}
              </div>
            }

            <p className='mb-2 font-weight-bold'>Select a reason <span className='text-danger'>*</span></p>
            {this.renderCancelReasons()}
            <div className="form-group mt-3 mb-0">
              <label htmlFor="commentArea" className='font-weight-bold'>Comment*</label>
              <textarea className="form-control" id="commentArea" value={comment} onChange={this.handleCommentChange} />
              <small className="text-muted">
                Feedback notes allow us to improve the app. 👊
              </small>
            </div>
          </div>
          <div className='modal-footer'>
            <button type="button" className="btn btn-danger btn-block" onClick={this.handleSubmit} disabled={isDisabled} >{submitButtonText}</button>
          </div>
        </div>
      </div>
    )
  }
}

CancelSubscriptionModal.propTypes = {
  reasons: PropTypes.array.isRequired,
  subscriptionId: PropTypes.number.isRequired
}

export default CancelSubscriptionModal