export const WORKOUT_TYPES = {
  fatBurning: 'fat_burning',
  strengthBuilding: 'strength_building',
  repBuilding: 'rep_building',
  tabata: 'tabata'
}

const HOST = location.hostname.replace('www.','').replace('admin.', '')

export const ADMIN_API_HOST = `${location.protocol}//api.${HOST}/admin`
export const API_HOST = `${location.protocol}//api.${HOST}/v1`