import React, { Component } from 'react'
import normalize from 'json-api-normalizer'

import ProgramPart from './ProgramPart'

class ProgramContainer extends Component {
  renderProgram = () => {
    const data =  normalize(this.props.data)

    let program = data.program
    const key = Object.keys(program)[0]
    program = program[key].attributes

    const parts = Object.values(data.part).map(record => record.attributes)
    const workouts = Object.values(data.workout).map(record => record.attributes)


    const renderParts = parts.map(part => {
      const partWorkouts = workouts.filter(workout => workout.programPartId === part.id)

      return <ProgramPart key={part.id} part={part} workouts={partWorkouts} />
    })

    return(
      <div>
        {renderParts}
      </div>
    )
  }

  render() {
    // TODO[Cristi] these transformations are duplicated in renderProgram,
    // we could do these once in contrusturctor and save the results in state
    const data =  normalize(this.props.data)
    let { program } = data
    const key = Object.keys(program)[0]
    program = data.program[key].attributes

    return(
      <div className='container'>
        <h1 className='text-center'>{program.name}</h1>
        <hr/>
        { this.renderProgram() }
      </div>
    )
  }
}

export default ProgramContainer
