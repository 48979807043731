import React from 'react'

const ModalHeader = ({ title }) =>
  <div className='modal-header'>
    <h5 className='mb-0'>{title}</h5>
    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

export default ModalHeader