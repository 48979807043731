import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react"

import ProgramsContainer from '../src/programs/ProgramsContainer'
import ProgramContainer from '../src/programs/ProgramContainer'

import WorkoutContainer from '../src/workouts/WorkoutContainer'

import CancelSubscriptionModal from '../src/subscriptions/CancelSubscriptionModal'

Sentry.init({ dsn: process.env.SENTRY_DSN })

document.addEventListener('turbolinks:load', () => {

  const programs  = document.getElementById('js-programs-data')
  const program   = document.getElementById('js-program-data')
  const workout   = document.getElementById('js-workout-data')

  // TODO[Cristi] check these
  if(programs) {
    const data = JSON.parse(programs.getAttribute('data'))

    ReactDOM.render(
      <ProgramsContainer data={data} />, programs
    )
  }

  if(program) {
    const data = JSON.parse(program.getAttribute('data'))

    ReactDOM.render(
      <ProgramContainer data={data} />, program
    )
  }

  if(workout) {
    const data = JSON.parse(workout.getAttribute('data'))

    ReactDOM.render(
      <WorkoutContainer data={data} />, workout
    )
  }

  $('.js-cancel-subscription').click(function () {
    const subscriptionId = $(this).data('subscription-id')
    const reasons = $(this).data('reasons')

    mountCancelSubscriptionModal(subscriptionId, reasons)
  })

})

function mountCancelSubscriptionModal(subscriptionId, reasons) {
  const modal = document.getElementById('js-modal')

  ReactDOM.render(<CancelSubscriptionModal subscriptionId={subscriptionId} reasons={reasons} />, modal)
}
