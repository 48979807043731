import React from "react";

const ProgramItem = ({
  id,
  name,
  description,
  difficultyLevel,
  likesCount,
  commentsCount
}) => (
  <div className="card card-body mb-4">
    <div className="d-inline-flex align-items-center mb-1">
      <h4 className="mb-0">{name}</h4>
      <p className="mb-0">
        &emsp;&mdash;&emsp; Likes: {likesCount} &emsp; Comments: {commentsCount}
      </p>
    </div>
    <h6 className="text-capitalize">{difficultyLevel}</h6>
    <hr />
    <p>{description}</p>
    <div className="container text-md-center">
      <a
        className="btn btn-primary text-uppercase"
        href={`/programs/${id}`}
        role="button"
      >
        Get Started Now!
      </a>
    </div>
  </div>
);

export default ProgramItem;
