import React, { Component } from 'react'
import normalize from 'json-api-normalizer'
import ProgramItem from './ProgramItem'

class ProgramsContainer extends Component {
  constructor(props) {
    super(props)
  }

  renderPrograms = () => {
    const programs = normalize(this.props.data).program

    /* ========================================================================
     * There are 2 ways to iterate an object,
     * let's see which one will work better down the road.
     * ======================================================================== */

    // Object.entries(programs).map((key, program) => console.log(key, program))
    // Object.keys(programs).map((key, i) => console.log(key, program[key]))

    return Object.keys(programs).map((key, i) => <ProgramItem key={key} {...programs[key].attributes} />)
  }

  render() {
    return(
      <div className='container'>
        <h1 className='mb-4 text-md-center text-uppercase'>Workout Programs</h1>
        { this.renderPrograms() }
      </div>
    )
  }
}

export default ProgramsContainer
