import React, { Component } from 'react'
import normalize from 'json-api-normalizer'

class WorkoutContainer extends Component {
  render() {
    const data = normalize(this.props.data)
    let workout = data.workout
    const key = Object.keys(workout)[0]
    workout = workout[key].attributes

    return(
      <div className='container'>
        <div className="card text-center">
          <div className="card-header font-weight-bold">{workout.name}</div>
          <div className="card-body">
            <div className="embed-responsive embed-responsive-21by9">
              <iframe className="embed-responsive-item" src={workout.featuredVideo}></iframe>
            </div>
          </div>
          <div className="card-footer text-muted">
            <h6 className='d-inline mr-3'>Likes: <span className="badge badge-info">{workout.likesCount}</span></h6>
            <h6 className='d-inline'>Comments: <span className="badge badge-info">{workout.commentsCount}</span></h6>
          </div>
        </div>
      </div>
    )
  }
}

export default WorkoutContainer
