import React from 'react'

const WorkoutItem = ({ workout }) => (
  <a href={`/workouts/${workout.id}`} className="btn btn-outline-info text-uppercase mr-2 mt-2">
    {workout.name}
  </a>
)

const ProgramPart = ({ part, workouts }) => (
  <div className="card text-center mb-4">
    <div className="card-header font-weight-bold">{part.name}</div>
    <div className="card-body">
      {workouts.map(workout => ( <WorkoutItem key={workout.id} workout={workout} />))}
    </div>
  </div>
)

export default ProgramPart
